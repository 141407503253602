<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="year" label="Tahun">
            <a-input-number v-model:value="formState.year" placeholder="Masukkan Tahun" />
          </a-form-item>
          <a-form-item name="divisi_id" label="Divisi">
            <a-select
              placeholder="Pilih Divisi"
              v-model:value="formState.divisi_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.divisi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="name" label="Nama">
            <a-input v-model:value="formState.name" placeholder="Masukkan Nama" />
          </a-form-item>
          <a-form-item name="parent_id" label="Parent KPI">
            <a-select
              placeholder="Pilih Parent KPI"
              v-model:value="formState.parent_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.kpiDivisi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="satuan_kpi_id" label="Satuan">
            <a-select
              placeholder="Pilih Satuan"
              v-model:value="formState.satuan_kpi_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.satuan" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="jenis_variable_kpi_id" label="Jenis Variable KPI">
            <a-select
              placeholder="Pilih Jenis Variable KPI"
              v-model:value="formState.jenis_variable_kpi_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.jenisVariableKpi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="target_nominal" label="Target Nominal">
            <a-input-number v-model:value="formState.target_nominal" placeholder="Masukkan Target Nominal" />
          </a-form-item>
          <a-form-item name="target_waktu" label="Target Waktu">
            <a-month-picker
              v-model:value="formState.target_waktu"
              placeholder="Pilih Bulan"
              format="MM-YYYY"
              valueFormat="YYYY-MM"
            />
          </a-form-item>
          <a-form-item name="polarisasi" label="Polarisasi">
            <a-select
              placeholder="Pilih Polarisasi"
              v-model:value="formState.polarisasi"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.polarisasi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="variable_kpi_divisi_id" label="Variable KPI">
            <a-select
              placeholder="Pilih Variable KPI"
              v-model:value="formState.variable_kpi_divisi_id"
              show-search
              :filter-option="filterSelect"
              mode="multiple"
            >
              <a-select-option v-for="option in options.variableKpiDivisi" :key="option.value">
                {{ option.text }} [{{ option.value }}]
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="formula" label="Formula">
            <a-input v-model:value="formState.formula" placeholder="Masukkan Formula" />
          </a-form-item>
          <a-form-item name="formula_description" label="Deskripsi Formula">
            <a-input v-model:value="formState.formula_description" placeholder="Masukkan Deskripsi Formula" />
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/master/kpi-divisi">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const KpiDivisiForm = {
  name: 'KpiDivisiForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();

    const formState = reactive({
      divisi_id: undefined,
      parent_id: undefined,
      satuan_kpi_id: undefined,
      jenis_variable_kpi_divisi_id: undefined,
      name: '',
      target_nominal: null,
      target_waktu: '',
      year: dateObj.getUTCFullYear(),
      polarisasi: undefined,
      formula: '',
      formula_description: '',
      variable_kpi_divisi_id: [],
    });

    const options = reactive({
      divisi: [],
      kpiDivisi: [],
      satuan: [],
      jenisVariableKpi: [],
      polarisasi: [
        {
          value: 'Positif',
          text: 'Positif',
        },
        {
          value: 'Negatif',
          text: 'Negatif',
        },
      ],
      variableKpiDivisi: [],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = values => {
      if (values.target_waktu) {
        let my = values.target_waktu.split('-');
        values['target_waktu'] = my[0] + '-' + my[1] + '-' + '01';
      }

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'kpi-divisi',
          data: values,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'kpi-divisi',
          data: values,
        });
      }
    };

    const rules = {
      divisi_id: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
      name: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', 'options-divisi-all').then(() => {
        options.divisi = crud.value;
      });

      dispatch('axiosCrudGetData', 'options-kpi-divisi').then(() => {
        options.kpiDivisi = crud.value;
      });

      dispatch('axiosCrudGetData', 'options-satuan-kpi').then(() => {
        options.satuan = crud.value;
      });

      dispatch('axiosCrudGetData', 'options-jenis-variable-kpi').then(() => {
        options.jenisVariableKpi = crud.value;
      });

      if (props.mode == 'Ubah') {
        dispatch('axiosCrudGetData', 'options-variable-kpi-divisi-unused-owned/' + params.id).then(() => {
          options.variableKpiDivisi = crud.value;
        });

        const data = {
          url: 'kpi-divisi',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });
        });
      } else {
        dispatch('axiosCrudGetData', 'options-variable-kpi-divisi-unused').then(() => {
          options.variableKpiDivisi = crud.value;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      filterSelect,
      options,
    };
  },
};

export default KpiDivisiForm;
</script>
